// material
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  CardMedia,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { alpha, styled, useTheme } from '@mui/material/styles'
import React, { useState } from 'react'

import useSettings from '../../../hooks/useSettings'
import palette from '../../../theme/palette'
import { MotionInView, varFadeInDown, varFadeInUp } from '../../animate'

// ----------------------------------------------------------------------

const CARDS = [
  {
    icon: '/static/icons/ic_design.svg',
    title: 'Create NFTs',
    description:
      'The set is built on the principles of the atomic design system. It helps you to create projects fastest and easily customized packages for your projects.',
  },
  {
    icon: '/static/icons/ic_code.svg',
    title: 'Share with fans',
    description: 'Easy to customize and extend each component, saving you time and money.',
  },
  {
    icon: '/static/brand/logo_single.svg',
    title: 'Trade easily',
    description: 'Consistent design in colors, fonts ... makes brand recognition easy.',
  },
]

const shadowIcon = (color: string) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('xs')]: {
    paddingTop: theme.spacing(4),
  },
}))
const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 64,
  height: 64,
  objectFit: 'cover',
  margin: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadiusSm,
}))

const AnchorButton = styled('a')(({ theme }) => ({
  display: 'flex',
  marginTop: '30px',
  textDecoration: 'none',
  fontWeight: 'bold',
  color: theme?.palette?.mode === 'light' ? theme.palette.grey[700] : theme.palette.common.white,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const CardStyle = styled(Card)(({ theme }) => {
  const shadowCard = (opacity: number) =>
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity)

  return {
    maxWidth: 380,
    minHeight: 440,
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(10, 5, 0),
    boxShadow: `-40px 40px 80px 0 ${shadowCard(0.48)}`,
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    '&.cardLeft': {
      [theme.breakpoints.up('md')]: { marginTop: -40 },
    },
    '&.cardCenter': {
      [theme.breakpoints.up('md')]: {
        marginTop: -80,
        backgroundColor: theme.palette.background.paper,
        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
        '&:before': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: 'auto',
          position: 'absolute',
          width: 'calc(100% - 40px)',
          height: 'calc(100% - 40px)',
          borderRadius: theme.shape.borderRadiusMd,
          backgroundColor: theme.palette.background.paper,
          boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
        },
      },
    },
  }
})

const themeModeType = {
  DARK: 'dark',
  LIGHT: 'light',
}

// ----------------------------------------------------------------------

export default function LandingMinimalHelps() {
  const theme = useTheme()
  const { themeMode } = useSettings()
  const SIMPLE_TAB = [
    {
      value: '1',
      bgColor: palette.light.background.bgOrange,
      textColor: palette.light.text.white,
      label: 'Fan engagement using NFT',
      // description:
      //   'With just a couple of clicks, your personal webstore comes to life and you are able to sell instantly, accepting payments on the blockchain. No need to wait for approvals. Lets go!',

      image: themeMode === themeModeType.DARK ? 'Group 1287 (1)' : 'NFT Collection',
      dottedLine: '/static/home/lin2.png',
      description:
        'Crypto payments accepted. Protected with military grade security Eco-friendly platform.',
    },
    {
      value: '2',
      bgColor:
        themeMode === themeModeType.DARK
          ? palette.light.background.bgOrange
          : palette.light.background.bgPurple,
      textColor: palette.light.text.white,
      label: 'Share with fans',
      description: (
        <span>
          Build your web3 identity <br /> Let your imagination guide you as fans
          <br /> discover and are awed by your
          <br /> offerings.
        </span>
      ),

      image: themeMode === themeModeType.DARK ? 'Group 1288' : 'Free NFT',
      dottedLine: '/static/home/lin2.png',
    },
    {
      value: '3',
      bgColor:
        themeMode === themeModeType.DARK
          ? palette.light.background.bgOrange
          : palette.light.background.bgCyan,
      textColor: palette.light.text.white,
      label: 'Trade easily',
      description: (
        <span>
          Artwork? Merch? Parties? VIP access?
          <br /> Recipes? Private classes? Or something
          <br /> completely new? <br />
          Unlimited digital possibilities with web3
          <br /> or just the good old in-real-life things.
        </span>
      ),

      image: themeMode === themeModeType.DARK ? 'Group 1289' : 'NFT Token',
    },
  ]
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const [value, setValue] = useState('1')
  const isLight = theme.palette.mode === 'light'
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <RootStyle>
      <Container maxWidth="lg">
        {/* <Box sx={{ mb: { xs: 10, md: 5 } }}>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h3" sx={{ textAlign: 'center', color: 'common.title' }}>
              Shop + Decentralized Finance
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="subtitle2" sx={{ textAlign: 'center', color: 'common.para' }}>
              (Made Super Simple)
            </Typography>
          </MotionInView>
        </Box>
        <Box sx={{ mb: { xs: 10, md: 5 } }}>
          <MotionInView variants={varFadeInDown}>
            <Typography
              align="center"
              sx={{ color: 'common.para', maxWidth: '800px', margin: '0 auto' }}
            >
              Harnessing the blockchain, we are able to merge shopping with DeFi, ensuring a
              constant movement of highly incentivized customers through all stores. This means that
              aside from having strong liquidity of cryptocurrency flows, we are opening the
              floodgates to the Cryptoverse and allowing for spending and real-world commerce in an
              accelerated fashion.
            </Typography>
          </MotionInView>
        </Box>
        <Box>
          <MotionInView variants={varFadeInUp}>
            <Box component="img" src={`/static/home/simplynft.png`} m="auto" />
          </MotionInView>
        </Box> */}
        <Grid container justifyContent="center">
          <Typography variant="h2" sx={{ letterSpacing: '1px' }}>
            In a nutshell
          </Typography>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 8 }}>
          {SIMPLE_TAB.map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  position: 'relative',
                }}
              >
                <CardMedia
                  component="img"
                  image={`/static/home/${_.image}.png`}
                  alt="Paella dish"
                  sx={{ width: '180px', transform: 'translateY(45px)' }}
                />
                <CardMedia
                  component="img"
                  image={_.dottedLine}
                  sx={{
                    position: 'absolute',
                    // top: '198px',
                    top: themeMode === themeModeType.DARK ? '221px' : '200px',
                    display: 'block',
                    left: '50%',
                    transform: 'translate(0, -45%)',
                    ['@media (max-width:900px)']: {
                      display: 'none',
                    },
                  }}
                />
                <Box
                  sx={{
                    display: { xs: 'none', sm: 'none', md: 'block' },
                    backgroundColor: _.bgColor,
                    color: _.textColor,
                    width: '42px',
                    height: '42px',
                    borderRadius: '50%',
                    lineHeight: '42px',
                    textAlign: 'center',
                    transform:
                      themeMode === themeModeType.DARK ? 'translateY(21px)' : 'translateY(0px)',
                    zIndex: 1,
                  }}
                >
                  {_.value}
                </Box>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: '20px',
                      alignItems: 'baseline',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="common.title"
                      sx={{
                        fontSize: '20px',
                        fontWeight: '700',
                        marginBottom: '20px',
                      }}
                    >
                      {_.label}
                    </Typography>
                    <Box
                      sx={{
                        display: { xs: 'block', sm: 'block', md: 'none' },
                        backgroundColor: _.bgColor,
                        color: _.textColor,
                        width: '42px',
                        height: '42px',
                        borderRadius: '50%',
                        lineHeight: '42px',
                        textAlign: 'center',
                        marginLeft: '10px',
                        zIndex: 1,
                      }}
                    >
                      {_.value}
                    </Box>
                  </Box>
                  {_?.description && (
                    <Typography
                      sx={{
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: isLight ? 'text.secondary' : 'text.white',
                      }}
                    >
                      {_.description}
                    </Typography>
                  )}

                  {/*<MotionInView variants={varFadeInUp}>*/}
                  {/*  <AnchorButton href="#">*/}
                  {/*    <KeyboardArrowRightIcon />*/}
                  {/*    Learn More*/}
                  {/*  </AnchorButton>*/}
                  {/*</MotionInView>*/}
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  )
}

import { useMediaQuery, Box, Button, Container, Typography, Grid } from '@mui/material'
import { styled, makeStyles } from '@mui/material/styles'
import { motion } from 'framer-motion'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useContext } from 'react'

import { isUserSubscribed } from '../../../../services/subscriptionPlan/getPlan'
import theme from '../../../../styles/theme'
import { IsUserSubscribedContext } from '../../../contexts/IsUserSubscribedProvider'
import WalletContext from '../../../contexts/WalletContext'
// material
//
import { PATH_DASHBOARD } from '../../../routes/paths'
import { MotionInView, varFadeInDown, varFadeInUp } from '../../animate'

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 456,
  margin: 'auto',
  overflow: 'hidden',
  paddingBottom: theme.spacing(10),
  borderRadius: theme.shape.borderRadiusMd,
  // backgroundImage: `linear-gradient(135deg,
  //   ${theme.palette.primary.main} 0%,
  //   ${theme.palette.primary.dark} 100%)`,
  backgroundImage: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    maxWidth: '100%',
    paddingBottom: 0,
    alignItems: 'center',
  },
}))
const OverlayStyle = styled(motion.img)({
  objectFit: 'cover',
  position: 'absolute',
  right: 0,
  top: '-69px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
})

// ----------------------------------------------------------------------

export default function LandingAdvertisement() {
  const wallet = useContext(WalletContext)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { isUserSubscribedStatus } = useContext(IsUserSubscribedContext)
  const { push } = useRouter()

  const connectWallet = useCallback(async () => {
    if (!wallet.address) {
      const walletSelected = await wallet.onboard?.walletSelect()
      if (!walletSelected) return false
      await wallet.onboard.walletCheck()
    }
  }, [wallet])

  const handleRedirectIsSubscribed = async (): Promise<void> => {
    if (isUserSubscribedStatus) push(PATH_DASHBOARD('').shop.list)
  }

  return (
    <Container maxWidth="lg" sx={{ my: 2 }}>
      <Grid container sx={{ alignItems: 'center', pt: 9, pb: 16 }}>
        <Grid item md={12} xs={12}>
          <Box
            sx={{
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <OverlayStyle alt="overlay" src="/static/home/Orava_acorn.png" />
            <MotionInView variants={varFadeInDown}>
              <Typography variant="h2" sx={{ maxWidth: '440px', margin: '0 auto' }}>
                Start your web3 fan engagement
              </Typography>

              {!wallet.address && (
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    borderRadius: '25px',
                    mt: 5,
                    // '&:hover': (theme) => ({ bgColor:theme.palette.primary.lighter}),
                    // background: (theme) =>
                    //   theme.palette.mode === 'light' ? 'none' : theme.palette.text.primary,
                  }}
                  // color={(theme) => theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.text.primary}
                  onClick={connectWallet}
                >
                  Connect Wallet
                </Button>
              )}
              {wallet.address && (
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    borderRadius: '25px',
                    mt: 5,
                    // '&:hover': (theme) => ({ bgColor:theme.palette.primary.lighter}),
                    // background: (theme) =>
                    //   theme.palette.mode === 'light' ? 'none' : theme.palette.text.primary,
                  }}
                  // color={(theme) => theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.text.primary}
                  onClick={handleRedirectIsSubscribed}
                >
                  Get started now
                </Button>
              )}
            </MotionInView>
          </Box>
        </Grid>
        {/* <Grid item md={6} xs={12}>
          <Box
            sx={{
              position: 'relative',
              textAlign: { xs: 'center' },
            }}
          >
            <MotionInView
              variants={varFadeInUp}
              sx={{
                mb: { xs: 3, md: 0 },
              }}
            >
              <motion.div animate={{ y: [0, 0, 0] }} transition={{ duration: 4, repeat: Infinity }}>
                <Box
                  component="img"
                  alt="rocket"
                  src="/static/home/Orava_acorn.png"
                  // sx={{ maxWidth: 460, width: 1 }}
                />
              </motion.div>
            </MotionInView>
            <Button
              size="large"
              variant="outlined"
              sx={{
                textAlign: 'center',
                whiteSpace: 'nowrap',
                borderRadius: '25px',
                position: { sm: 'static', md: 'absolute' },
                top: { md: '70%' },
                left: { md: '1%' },
                // '&:hover': (theme) => ({ bgColor:theme.palette.primary.lighter}),
                // background: (theme) =>
                //   theme.palette.mode === 'light' ? 'none' : theme.palette.text.primary,
              }}
              // color={(theme) => theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.text.primary}
              onClick={handleRedirectIsSubscribed}
            >
              Get started now
            </Button>
          </Box>
        </Grid> */}
      </Grid>
    </Container>
  )
}

import checkmarkFill from '@iconify/icons-eva/checkmark-fill'
import chevronRightFill from '@iconify/icons-eva/chevron-right-fill'
import { Icon } from '@iconify/react'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
// material
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { alpha, styled, useTheme } from '@mui/material/styles'
import { useFormik, Form, FormikProvider } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { getPlans } from '../../../../services/subscriptionPlan/getPlans'
import { requestSubscription } from '../../../../services/subscriptionPlan/request'
import Congratulations from '../../../assets/congratulation'
import WalletContext, { useWalletContext } from '../../../contexts/WalletContext'
import { MotionInView, varFadeIn, varFadeInDown, varFadeInUp, DialogAnimate } from '../../animate'
// ----------------------------------------------------------------------

const DialogStyle = styled(DialogAnimate)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 48px)',
      maxHeight: 'calc(100% - 48px)',
    },
  },
}))

const PLANS = [
  {
    title: 'Standard',
    planId: {
      monthly: 'c2440a4e-4d60-49e6-a651-21fc567d751b',
      yearly: '643a74e8-aded-4385-9b40-7ddc2ab00df5',
    },
    price: {
      monthly: '$297',
      annual: '$2,000',
    },
    features: ['1 Marketplace', '100 Collection', '3% platform fee all transactions'],
  },
  {
    title: 'Pro',
    planId: {
      monthly: '110f4641-cb22-4f12-8a70-25c59455e0ae',
      yearly: '9efaafe6-dded-47ab-a8fa-574181bfeb61',
    },
    price: {
      monthly: '$697',
      annual: '$6,000',
    },
    features: ['5 Marketplace', 'unlimited Collection', '1.75% platform fee all transactions'],
  },
  {
    title: 'Extended',
    planId: {
      monthly: '5a13f8f5-e6c5-4312-978f-3f7863f503fd',
      yearly: '',
    },
    price: {
      monthly: '$2,000',
      annual: '',
    },
    features: ['unlimited Marketplace', 'unlimited Collection', 'no fees (UNLIMITED OPTION)'],
  },
]

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15),
  },
}))

// ----------------------------------------------------------------------

type PlanCardProps = {
  plan: {
    title: string
    planId: {
      monthly: string
      yearly: string
    }
    price: {
      monthly: string
      annual: string
    }
    features: [] | string[]
  }
  cardIndex: number
}
function PlanCard({ plan, cardIndex }: PlanCardProps) {
  const theme = useTheme()
  const isLight = theme.palette.mode === 'light'
  const { enqueueSnackbar } = useSnackbar()

  const { title, price, features, planId } = plan
  const [open, setOpen] = useState(false)
  const [planType, setPlanType] = React.useState('')
  const [subscriptionDone, setSubscriptionDone] = useState(false)
  const { address } = useContext(WalletContext)
  const [subscriptionPlanId, setSubscriptionPlanId] = useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleChangePlanType = (event) => {
    if (event.target.value === 'Monthly') {
      setPlanType('Monthly')
      setSubscriptionPlanId(planId.monthly)
    } else {
      setPlanType('Yearly')
      setSubscriptionPlanId(planId.yearly)
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        if (!address) {
          enqueueSnackbar('Please connect your wallet', { variant: 'error' })
          return
        }
        if (!planType) {
          enqueueSnackbar('Please select a plan type', { variant: 'error' })
          return
        }
        setSubmitting(true)
        await requestSubscription(subscriptionPlanId, address, values.email).then(() => {
          setSubscriptionDone(true)
        })
      } catch (error) {
        setErrors(error.message)
      }
    },
  })

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik

  // useEffect(() => {
  //   getPlans().then((res) => {
  //     // if (res.data.length > 0) {
  //     //   setSubscriptionDone(true)
  //     // }
  //     console.log(res)
  //   })
  // }, [])
  return (
    <>
      <Card
        sx={{
          p: 5,
          boxShadow: (theme) =>
            `0px 48px 80px ${alpha(
              isLight ? theme.palette.grey[500] : theme.palette.common.black,
              0.12
            )}`,
          ...(cardIndex === 1 && {
            boxShadow: (theme) =>
              `0px 48px 80px ${alpha(
                isLight ? theme.palette.grey[500] : theme.palette.common.black,
                0.48
              )}`,
          }),
        }}
      >
        <Stack spacing={3}>
          <div>
            <Typography variant="overline" sx={{ mb: 2, color: 'text.disabled', display: 'block' }}>
              {title}
            </Typography>
            <Grid container>
              <Grid item xs>
                <Typography variant="h4" display={'inline'}>
                  {price.monthly}
                </Typography>
                <Typography variant="caption">month</Typography>
              </Grid>
              {price.annual && (
                <>
                  <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
                  <Grid item xs>
                    <Typography variant="h4" display={'inline'}>
                      {price.annual}
                    </Typography>
                    <Typography variant="caption">year</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
          <Stack spacing={1}>
            {features.map((option) => (
              <Stack key={option} spacing={1.5} direction="row" alignItems="center">
                <Box
                  component={Icon}
                  icon={checkmarkFill}
                  sx={{ color: 'primary.main', width: 20, height: 20 }}
                />
                <Typography variant="body2">{option}</Typography>
              </Stack>
            ))}
          </Stack>
          <Button
            size="large"
            fullWidth
            variant={cardIndex === 1 ? 'contained' : 'outlined'}
            onClick={handleClickOpen}
          >
            Choose Plan
          </Button>
        </Stack>
      </Card>
      <FormikProvider value={formik}>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          {!subscriptionDone ? (
            <>
              <Form onSubmit={handleSubmit}>
                <DialogTitle>
                  You are subscribing to plan <b>{title}</b>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    To subscribe to this subscription plan, please enter your email address here.
                  </DialogContentText>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={planType}
                    onChange={handleChangePlanType}
                  >
                    <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
                    {title !== 'Extended' && (
                      <FormControlLabel value="Yearly" control={<Radio />} label="Yearly" />
                    )}
                  </RadioGroup>
                  <TextField
                    fullWidth
                    type="email"
                    margin="dense"
                    variant="outlined"
                    label="Email Address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    disabled={isSubmitting}
                    variant="outlined"
                    size="medium"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    size="medium"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Subscribe
                  </LoadingButton>
                </DialogActions>
              </Form>
            </>
          ) : (
            <DialogContent>
              <Box sx={{ p: 4, maxWidth: 480, margin: 'auto' }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h4" paragraph>
                    Thank you for subscribing to <b>{title}</b>
                  </Typography>

                  <Congratulations sx={{ mx: 1 }} />

                  <Typography align="left" sx={{ color: 'text.secondary' }}>
                    Please check your email for further steps after that you can start using the
                    Orava.
                    <br /> <br /> If you have any question or queries then fell to get in contact
                    us. <br /> <br /> All the best :)
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          )}
        </Dialog>
      </FormikProvider>
    </>
  )
}

export default function LandingPricingPlans() {
  const theme = useTheme()
  const isLight = theme.palette.mode === 'light'

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: 10, textAlign: 'center' }}>
          <MotionInView variants={varFadeInUp}>
            <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
              pricing plans
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h2" sx={{ mb: 3 }}>
              The right plan for your business
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
            <Typography
              sx={{
                color: isLight ? 'text.secondary' : 'text.primary',
              }}
            >
              Choose the perfect plan for your needs. Always flexible to grow
            </Typography>
          </MotionInView>
        </Box>

        <Grid container spacing={5}>
          {PLANS.map((plan, index) => (
            <Grid key={plan.title} item xs={12} md={4}>
              <MotionInView variants={index === 1 ? varFadeInDown : varFadeInUp}>
                <PlanCard plan={plan} cardIndex={index} />
              </MotionInView>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  )
}

// material
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { MotionInView, varFadeInDown } from '../../animate'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  [theme.breakpoints.up('xs')]: {
    marginBottom: theme.spacing(13),
  },
}))

// ----------------------------------------------------------------------

export default function LandingMinimalHelps() {
  return (
    <RootStyle>
      <MotionInView variants={varFadeInDown}>
        <Typography
          variant="h3"
          sx={{
            textAlign: 'center',
            fontSize: '2rem',
            lineHeight: '48px',
            fontWeight: 'bold',
            letterSpacing: '2px',
          }}
        >
          Create NFTs.
        </Typography>
        <Typography
          variant="h3"
          sx={{
            textAlign: 'center',
            fontSize: '2rem',
            lineHeight: '48px',
            fontWeight: 'bold',
            letterSpacing: '2px',
          }}
        >
          Share with fans.
        </Typography>
        <Typography
          variant="h3"
          sx={{
            textAlign: 'center',
            fontSize: '2rem',
            lineHeight: '48px',
            fontWeight: 'bold',
            letterSpacing: '2px',
            my: 1,
          }}
        >
          Trade easily.
        </Typography>
        {/* <Typography
          align="center"
          sx={{ fontSize: '16px', lineHeight: '24px', color: 'common.para' }}
        >
          The latest generation of internet applications and services powered by distributed ledger
          technology, the most common being blockchain.
        </Typography> */}
      </MotionInView>
    </RootStyle>
  )
}

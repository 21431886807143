import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import { Icon } from '@iconify/react'
// next
import {
  Box,
  BoxProps,
  Collapse,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import { alpha, styled, useTheme } from '@mui/material/styles'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, useState } from 'react'
// material

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}))

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode
  to?: string
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  borderTopRightRadius: '50px',
  borderBottomRightRadius: '50px',
}))

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string
  path: string
  icon?: JSX.Element
  info?: JSX.Element
  children?: {
    title: string
    path: string
    actualPath: string
  }[]
}

function NavItem({ item, isShow }: { item: NavItemProps; isShow?: boolean | undefined }) {
  const theme = useTheme()
  const { pathname } = useRouter()
  const { title, path, icon, info, children } = item
  const isActiveRoot =
    pathname.split('/')[pathname.split('/').length - 1] ===
      path.split('/')[path.split('/').length - 1] ||
    pathname.split('/')[pathname.split('/').length - 2] ===
      path.split('/')[path.split('/').length - 1]

  const [open, setOpen] = useState(isActiveRoot)

  const handleOpen = () => {
    setOpen(!open)
  }

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
  }

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          className={title}
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>
        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path, actualPath } = item

                const isActiveSub = pathname.includes(path) || pathname.includes(actualPath)
                return (
                  <NextLink key={title} href={path} shallow={true}>
                    <ListItemStyle
                      sx={{
                        ...(isActiveSub && activeSubStyle),
                      }}
                    >
                      <ListItemIconStyle>
                        <Box
                          component="span"
                          sx={{
                            width: 4,
                            height: 4,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'text.disabled',
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              transform: 'scale(2)',
                              bgcolor: 'primary.main',
                            }),
                          }}
                        />
                      </ListItemIconStyle>
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                  </NextLink>
                )
              })}
            </List>
          </Collapse>
        )}
      </>
    )
  }

  return (
    <NextLink href={path}>
      <a
        href={path}
        target={title === 'Preview Marketplace' ? '_blank' : ''}
        rel="noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <ListItemStyle
          className={title}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
            </>
          )}
        </ListItemStyle>
      </a>
    </NextLink>
  )
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined
  navConfig: {
    subheader: string
    items: NavItemProps[]
  }[]
}

export default function NavSection({ navConfig, isShow = true, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list
        return (
          <List key={subheader} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} isShow={isShow} />
            ))}
          </List>
        )
      })}
    </Box>
  )
}

export { NavItem }

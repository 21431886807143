import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import { Icon } from '@iconify/react'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import {
  ToggleButton,
  Box,
  Button,
  Collapse,
  Drawer,
  LinkProps,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  ListSubheader,
} from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react'

// eslint-disable-next-line import/order
import { getShopName } from '../../../utils/checkShop'
import Iconify from '../../components/Iconify'
// next
// material
// components
import Logo from '../../components/Logo'
import UserTooltip from '../../components/marketplace/shop/ShopTooltip'
import { NavItem } from '../../components/NavSection'
import Scrollbar from '../../components/Scrollbar'
import WalletContext from '../../contexts/WalletContext'
import useSettings from '../../hooks/useSettings'
import { truncateAddress } from '../../utils/addressTruncate'

import { MenuItemProps, MenuProps } from './MainNavbar'

// ----------------------------------------------------------------------

const ICON_SIZE = 22
const ITEM_SIZE = 48
const PADDING = 2.5

type StyleProps = LinkProps & ListItemButtonProps

interface ListItemStyleProps extends StyleProps {
  component?: ReactNode
  to?: string
  end?: boolean
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
}))

// ----------------------------------------------------------------------

type MenuMobileItemProps = {
  item: MenuItemProps
  isOpen: boolean
  path: string
  isActive: boolean
  onOpen: VoidFunction
  handleDrawerClose: VoidFunction
}
const Header = styled('h3')(({ theme }) => ({
  mt: 0.5,
  color: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
}))

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}))

function MenuMobileItem({
  item,
  isOpen,
  path,
  isActive,
  onOpen,
  handleDrawerClose,
}: MenuMobileItemProps): JSX.Element {
  const { title, icon, children, onChange } = item
  const { setOpen } = useSettings()

  const handleOpenThemeSetting = () => {
    setOpen(true)
    handleDrawerClose()
  }

  if (children) {
    return (
      <>
        <ListItemStyle onClick={onOpen}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
          <Box
            component={Icon}
            icon={isOpen ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
            {children.map(({ subheader, items }) => (
              <List key={subheader} disablePadding>
                {subheader && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
                {items.map((item) => (
                  <NavItem key={item.title} item={item} isShow={true} />
                ))}
              </List>
            ))}
          </Box>
        </Collapse>
      </>
    )
  }
  if (!onChange) {
    return (
      <NextLink key={title} href={path}>
        <ListItemStyle
          sx={{
            ...(isActive && {
              color: 'primary.main',
              fontWeight: 'fontWeightMedium',
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            }),
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
        </ListItemStyle>
      </NextLink>
    )
  }
  return (
    <ListItemStyle
      onClick={handleOpenThemeSetting}
      sx={{
        ...(isActive && {
          color: 'primary.main',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        }),
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  )
}

const CustomNavBar = ({ navItem, pathname, shopName, handleDrawerClose }) => {
  const { id, link, icon, type, isEnabled, disabled, dropdownlist } = navItem
  const [open, setOpen] = useState(false)
  const item = {
    title: id,
    path: '',
    icon: icon || <Iconify icon="entypo:link" sx={{ width: 20, height: 20 }} />,
    children: [
      {
        subheader: null,
        items:
          dropdownlist?.map(({ page, pageURL }) => ({
            title: page,
            path: `/${pageURL}`,
          })) || [],
      },
    ],
  }
  const handleOpen = () => {
    setOpen((prev) => !prev)
  }
  if (!isEnabled || !(dropdownlist?.length > 0)) return null
  return (
    <MenuMobileItem
      key={id}
      item={item}
      isOpen={open}
      onOpen={handleOpen}
      path={link}
      isActive={link === pathname}
      handleDrawerClose={handleDrawerClose}
    />
  )
}

export default function MenuMobile({
  isOffset,
  isHome,
  navConfig,
  isShop,
  address,
  customNavBar,
}: MenuProps) {
  const { pathname, query } = useRouter()
  const [open, setOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  const wallet = useContext(WalletContext)
  const connectWallet = useCallback(async () => {
    if (!wallet.address) {
      const walletSelected = await wallet.onboard?.walletSelect()
      if (!walletSelected) return false
      await wallet.onboard.walletCheck()
    }
  }, [wallet])
  const handleOpen = () => {
    setOpen(!open)
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }
  const shopDetails = getShopName()
  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <ToggleButton
          value="none"
          onClick={handleDrawerOpen}
          sx={{
            ml: 1,
            borderRadius: '50%',
            color: 'text.primary',
            padding: '7px',
          }}
        >
          <MenuIcon />
        </ToggleButton>
      </Stack>

      <Drawer
        sx={{ zIndex: 10000000 }}
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: '100%' } }}
      >
        <Scrollbar>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {isShop ? (
              <div style={{ width: '100%', whiteSpace: 'nowrap', marginLeft: '20px' }}>
                <NextLink href={'/'}>
                  <Header>
                    <Typography variant={'h3'} sx={{ lineHeight: 0.5, cursor: 'pointer' }}>
                      {shopDetails.shopName}
                    </Typography>
                  </Header>
                </NextLink>
                <Typography
                  variant="caption"
                  sx={{ color: 'text.disabled', display: 'inline', ml: 1 }}
                >
                  by &nbsp;
                </Typography>
                <UserTooltip
                  address={address}
                  label={
                    <Typography
                      variant="caption"
                      sx={{
                        color: 'text.primary',
                        display: 'inline',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {truncateAddress(address)}
                    </Typography>
                  }
                />
              </div>
            ) : (
              <NextLink href="/">
                <a>
                  <Logo sx={{ mx: PADDING, my: 0, mr: 0 }} />
                </a>
              </NextLink>
            )}
            <ToggleButton
              value="none"
              sx={{
                mx: PADDING,
                my: 3,
                padding: '7px',
                borderRadius: '50%',
                color: 'text.primary',
              }}
              size={'medium'}
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </ToggleButton>
          </Box>
          <List disablePadding>
            {navConfig.map((link) => {
              const { actualPath, path, title } = link
              let newPath = path
              let isActive = actualPath ? actualPath === pathname : pathname === path

              if (title === 'My Profile') {
                if (!wallet?.address) return null
                newPath = path.replace('/profile', `/profile/${wallet.address}`)
                isActive = isActive && wallet.address === query?.address
              }
              return (
                <MenuMobileItem
                  key={title}
                  item={link}
                  isOpen={open}
                  onOpen={handleOpen}
                  path={newPath}
                  isActive={isActive}
                  handleDrawerClose={handleDrawerClose}
                />
              )
            })}
            {customNavBar?.map((navItem) => (
              <CustomNavBar
                key={navItem.id}
                shopName={shopDetails?.shopName}
                navItem={navItem}
                pathname={pathname}
                handleDrawerClose={handleDrawerClose}
              />
            ))}
          </List>
        </Scrollbar>
        {!wallet.address && (
          <Box
            borderTop="1px solid grey"
            position="fixed"
            bottom="0"
            width="100%"
            display="flex"
            bgcolor="background.paper"
            justifyContent="center"
            p={1.5}
          >
            <Button
              variant="contained"
              className="connectMetamask"
              onClick={() => {
                handleDrawerClose()
                connectWallet()
              }}
            >
              Connect Wallet
            </Button>
          </Box>
        )}
      </Drawer>
    </>
  )
}

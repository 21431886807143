import { Box, Dialog, DialogProps, Paper } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'

// material
//
import { varFadeInUp } from './variants'

// ----------------------------------------------------------------------

interface DialogAnimateProps extends DialogProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  animate?: object
  onClose?: VoidFunction
}

export default function DialogAnimate({
  open = false,
  animate,
  onClose,
  children,
  sx,
  ...other
}: DialogAnimateProps) {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={onClose}
          sx={{ zIndex: 1200, margin: 0 }}
          PaperComponent={(props) => (
            <Box
              component={motion.div}
              {...(animate || varFadeInUp)}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0,
              }}
            >
              <Box onClick={onClose} sx={{ width: '100%', height: '100%', position: 'fixed' }} />
              <Paper {...props} sx={{ zIndex: 1 }}>
                {props.children}
              </Paper>
            </Box>
          )}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  )
}

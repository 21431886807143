/* eslint-disable prettier/prettier */
import { useLayoutEffect, useState } from 'react'

export const useMediaQuery = (): Array<number> => {
  const [screenSize, setScreenSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateScreenSize)
    updateScreenSize()
    return () => window.removeEventListener('resize', updateScreenSize)
  }, [])
  return screenSize
}
export default useMediaQuery

import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import { ToggleButton } from '@mui/material'
import React, { useEffect } from 'react'

import useSettings from '../hooks/useSettings'

export default function ThemeMode({ sx }) {
  const [selected, setSelected] = React.useState(false)
  const { themeMode, onChangeMode } = useSettings()
  const changeThemeMode = () => {
    onChangeMode(themeMode === 'light' ? 'dark' : 'light')
    setSelected(!selected)
  }
  useEffect(() => {
    themeMode === 'dark' ? setSelected(true) : setSelected(false)
  }, [themeMode])
  return (
    <ToggleButton
      value={themeMode}
      selected={selected}
      onChange={changeThemeMode}
      size={'medium'}
      sx={sx}
    >
      {selected ? <WbSunnyIcon /> : <NightsStayOutlinedIcon />}
    </ToggleButton>
  )
}

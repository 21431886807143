// import { Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import dynamic from 'next/dynamic'
// import { useContext, useState } from 'react'
// import { useInView } from 'react-cool-inview'

// import gradient from '../public/static/gradient bg/orava_gradient_bg_dark.jpg'
import { LandingHero } from '../src/components/_external-pages/landing'
import Page from '../src/components/Page'
// import WalletContext from '../src/contexts/WalletContext'
import useSettings from '../src/hooks/useSettings'
import MainLayout from '../src/layouts/main'
import palette from '../src/theme/palette'
// import theme from '../styles/theme'

// const LandingHero = dynamic(() => import('../src/components/_external-pages/landing/LandingHero'))
// const LandingMinimal = dynamic(
//   () => import('../src/components/_external-pages/landing/LandingMinimal')
// )
const LandingHugePackElements = dynamic(
  () => import('../src/components/_external-pages/landing/LandingHugePackElements'),
  {
    ssr: false,
  }
)
const LandingAdvertisement = dynamic(
  () => import('../src/components/_external-pages/landing/LandingAdvertisement'),
  {
    ssr: false,
  }
)
// const LandingCleanInterfaces = dynamic(
//   () => import('../src/components/_external-pages/landing/LandingCleanInterfaces'),
//   {
//     ssr: false,
//   }
// )
const LandingHowItWorks = dynamic(
  () => import('../src/components/_external-pages/landing/LandingHowItWorks'),
  {
    ssr: false,
  }
)
// const LandingPricingPlans = dynamic(
//   () => import('../src/components/_external-pages/landing/LandingPricingPlans'),
//   {
//     ssr: false,
//   }
// )

const RootStyle = styled(Page)({
  height: '100%',
})

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
}))
// const ThemeGradient = styled('div')(({ theme }) => ({
//   backgroundImage: gradient,
// }))
const themeModeType = {
  DARK: 'dark',
  LIGHT: 'light',
}
const AnchorButton = styled('span')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  textDecoration: 'none',
  color: theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.common.white,
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up('xs')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
  },
}))
const LandingPage = () => {
  const { themeMode } = useSettings()
  const SOLUTIONS = [
    {
      header: 'Your House, Your Rules',
      // description: `
      //   Use awesome marketing tools to push out your products to the right crowd, all the time. Promote with laser precision and increase eyeballs (and sales). The blockchain never sleeps, and neither will your marketplace.`,
      image:
        themeMode === themeModeType.DARK ? 'NFT Certificate_squirrell' : 'NFT Auction_squirrell',
      direction: 'row-reverse',
      textColor:
        themeMode === themeModeType.DARK
          ? palette.light.text.Squirrel
          : palette.light.text.Squirrel,
      // navigationText: 'Discover how to get customers',
      data: [
        {
          title: 'Fuss-free setup',
          text: 'One-click, and you have created your web3 place for fan engagement',
          navText: '',
          image: themeMode === themeModeType.DARK ? '' : 'Blockchain',
        },
        {
          title: 'Your own branded place',
          text: 'Use your brand identity with wealth of options to customize.',
          image: themeMode === themeModeType.DARK ? '' : 'Minting 3',
        },
        {
          title: 'Monetize easy',
          text: (
            <span>
              Set the price for digital collectibles, VIP access to content or any other fan
              engagement you have created, Your journey into{' '}
              <AnchorButton sx={{ display: 'inline' }}>web3 fan engagement begins</AnchorButton>{' '}
              here!
            </span>
          ),
          image: themeMode === themeModeType.DARK ? '' : 'Wallet-1',
        },
        {
          title: 'Your Terms and Conditions',
          text: 'Set terms and conditions easily and securely for your fanbase',
          image: themeMode === themeModeType.DARK ? '' : 'NFT Certificate_squirrell',
        },
      ],
    },
  ]
  // const wallet = useContext(WalletContext)
  // const [run, setrun] = useState(false)
  // const isJoyride = getStorage('joyrideHomePage')
  // if (isJoyride !== 'completed') {
  //   setTimeout(() => {
  //     setrun(true)
  //   }, 5000)
  // }
  // const handleClick = (e) => {
  //   e.preventDefault()
  //   setrun(true)
  // }
  // const handleJoyrideCallback = (data: CallBackProps) => {
  //   const { status, type } = data
  //   const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]
  //
  //   if (finishedStatuses.includes(status)) {
  //     setStorage('joyrideHomePage', 'completed')
  //     setrun(false)
  //   }
  //
  //   // console.groupCollapsed(type);
  //   // console.log(data);
  //   // console.groupEnd();
  // }
  // const { observe, inView } = useInView()
  return (
    <>
      {/*<ReactJoyride*/}
      {/*  callback={handleJoyrideCallback}*/}
      {/*  scrollToFirstStep={true}*/}
      {/*  steps={[*/}
      {/*    {*/}
      {/*      content: `Let's begin our journey!`,*/}
      {/*      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },*/}
      {/*      placement: 'center',*/}
      {/*      target: 'body',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      target: wallet.address ? '.manageStore' : '.connectMetamask',*/}
      {/*      content: wallet.address*/}
      {/*        ? 'Manage your store with just one click'*/}
      {/*        : 'Connect to metamask to connect account',*/}
      {/*    },*/}
      {/*    ...joyrideSteps.homepage,*/}
      {/*  ]}*/}
      {/*  run={run}*/}
      {/*  continuous*/}
      {/*  showProgress*/}
      {/*  showSkipButton*/}
      {/*  styles={JOYRIDE_STYLES}*/}
      {/*/>*/}

      <MainLayout>
        <RootStyle title="Welcome to Orava" id="move_top">
          <LandingHero />
          <LandingHowItWorks />
          <ContentStyle>
            {/* <LandingMinimal /> */}
            {SOLUTIONS.map((val) => (
              <LandingHugePackElements
                key={val.header}
                header={val.header}
                image={val.image}
                direction={val.direction}
                data={val.data}
                textColor={val?.textColor}
              />
            ))}
            {/* <LandingHowItWorks /> */}
            {/* <LandingPricingPlans /> */}
            {/*<LandingCleanInterfaces />*/}
            <LandingAdvertisement />
          </ContentStyle>
        </RootStyle>
      </MainLayout>
    </>
  )
}
export default LandingPage

// material
import { Box, Grid, Container, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

//
import { MotionInView, varFadeInUp } from '../../animate'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}))

// ----------------------------------------------------------------------

export default function LandingCleanInterfaces() {
  const theme = useTheme()
  const isLight = theme.palette.mode === 'light'

  return (
    <RootStyle
      sx={{
        marginBottom: '80px',
        background: `${!isLight ? '#000' : '#fff'}`,
        padding: '90px 0px',
        marginTop: '0',
      }}
    >
      <Container maxWidth="lg">
        <Box>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Typography
                variant="h3"
                paragraph
                sx={{ paddingBottom: '25px', textAlign: 'center' }}
              >
                We support multichains. We have no limits
              </Typography>
            </Grid>
            {Array.from(Array(5)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={index} spacing={3}>
                <MotionInView
                  variants={varFadeInUp}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  {isLight ? (
                    <Box
                      component="img"
                      src={`/static/home/partners/${index}.png`}
                      sx={{ py: 3 }}
                    />
                  ) : (
                    <Box component="img" src={`/static/home/partners/${index}.svg`} />
                  )}
                </MotionInView>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </RootStyle>
  )
}

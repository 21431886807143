import axios from 'axios'

import { env } from '../../configs'

export const requestSubscription = async (
  planId: string,
  ownerAddress: string,
  email: string
): Promise<requestSubscriptionReturnType> => {
  try {
    axios.post(`${env.BE_BASE_URI}/api/subscription-request`, {
      planId,
      ownerAddress,
      email,
    })
    return { success: true }
  } catch (e) {
    return { success: false }
  }
}

interface requestSubscriptionReturnType {
  success: boolean
  [key: string]: any
}

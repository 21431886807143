// styles/theme.ts

import { createTheme } from '@mui/material'

// Create a theme instance.
// Basic Palette
const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
  },
})

export default theme

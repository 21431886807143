// material
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, Container, Grid, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { size } from 'lodash'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import { IsUserSubscribedContext } from '../../../contexts/IsUserSubscribedProvider'
import useSettings from '../../../hooks/useSettings'
import { MotionInView, varFadeInUp } from '../../animate'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 0),
  backgroundImage: 'none',
}))

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    marginBottom: 0,
  },
}))

const AnchorButton = styled('span')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  textDecoration: 'none',
  color: theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.common.white,
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up('xs')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
  },
}))

// ----------------------------------------------------------------------
const themeModeType = {
  DARK: 'dark',
  LIGHT: 'light',
}

interface LandingHugePackElementsProps {
  header: string
  direction: any
  image: string
  textColor?: string
  navigationText?: string
  backgroundColor?: string
  data: { title?: string; text: string | React.ReactNode; navText?: string; image?: string }[]
}

const LandingHugePackElements: React.FC<LandingHugePackElementsProps> = ({
  header,
  direction,
  image,
  navigationText,
  textColor,
  data,
  backgroundColor,
}) => {
  const theme = useTheme()
  const isLight = theme.palette.mode === 'light'

  const { themeMode } = useSettings()
  const { isUserSubscribedStatus } = useContext(IsUserSubscribedContext)
  const { push } = useRouter()

  const handleRedirectIsSubscribed = async (): Promise<void> => {
    if (isUserSubscribedStatus) push('shop/create')
  }

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          direction={direction}
          sx={{
            background: backgroundColor,
            width: '100%',
            marginLeft: '0',
            borderRadius: '10px',
            marginTop: '0',
            padding: themeMode === themeModeType.DARK ? ` 30px 0` : '40px 0',
          }}
        >
          <Grid item xs={12} sm={6} md={6} dir="ltr">
            <MotionInView variants={varFadeInUp}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                }}
              >
                <img alt={`screen`} src={`/static/home/${image}.png`} />
              </Box>
            </MotionInView>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ pr: 3 }}>
            <ContentStyle>
              <MotionInView variants={varFadeInUp}>
                <Typography variant="h3" sx={{ mb: 3, color: textColor }}>
                  {header}
                </Typography>
              </MotionInView>

              {data?.length > 0 && (
                <MotionInView variants={varFadeInUp}>
                  <Box sx={{ mb: 5 }}>
                    {data.map(({ title, text, navText, image }) => {
                      return (
                        <Box
                          key={title}
                          sx={{
                            display: 'flex',
                            alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'center' },
                            mb: 3,
                          }}
                        >
                          {image && (
                            <img
                              alt={`screen`}
                              src={`/static/home/${image}.png`}
                              style={{ width: '80px', paddingRight: '10px' }}
                            />
                          )}

                          <Box
                            sx={{
                              ['@media (max-width:900px)']: {
                                justifyContent: 'center',
                                width: '100%',
                              },
                            }}
                          >
                            {title && <Typography variant="h5">{title} </Typography>}
                            <Typography
                              sx={{
                                color: isLight ? 'text.secondary' : 'text.white',
                              }}
                            >
                              {text}
                              {navText && (
                                <AnchorButton
                                  onClick={handleRedirectIsSubscribed}
                                  sx={{ display: 'inline' }}
                                >
                                  {navText}
                                </AnchorButton>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                </MotionInView>
              )}
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  )
}

export default LandingHugePackElements

import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill'
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill'
import { Icon } from '@iconify/react'
import {
  Box,
  Button,
  CardActionArea,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  Link,
  LinkProps,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Popper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'
// next
import NextLink from 'next/link'
import router, { useRouter } from 'next/router'
import React, { ReactNode, useContext, useEffect, useState } from 'react'

import { env } from '../../../configs'
import { LoadingState } from '../../../services/nftGated/pages/types'
import { getShopName } from '../../../utils/checkShop'
// material
//
import WalletContext from '../../contexts/WalletContext'
import PreviewNextLink from '../../hoc/PreviewNextLink'

import { MenuItemProps, MenuProps } from './MainNavbar'

// ----------------------------------------------------------------------

interface RouterLinkProps extends LinkProps {
  component?: ReactNode
  to?: string
  end?: boolean
}

const LinkStyle = styled(Link)<RouterLinkProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest,
  }),
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  color: theme.palette.mode === 'dark' ? 'text.primary' : 'common.nav',
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none',
  },
}))

const ListItemStyle = styled(ListItem)<RouterLinkProps>(({ theme }) => ({
  ...theme.typography.body2,
  padding: 0,
  marginTop: theme.spacing(3),
  color: theme.palette.text.secondary,
  transition: theme.transitions.create('color'),
  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

// ----------------------------------------------------------------------

export type IconBulletProps = {
  type?: 'subheader' | 'item'
}

function IconBullet({ type = 'item' }: IconBulletProps) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && {
            ml: 0,
            width: 8,
            height: 2,
            borderRadius: 2,
          }),
        }}
      />
    </Box>
  )
}

type MenuDesktopItemProps = {
  item: MenuItemProps
  isActive: boolean
  path: string
  isOpen: boolean
  isHome: boolean
  isOffset: boolean
  onOpen: VoidFunction
  onClose: VoidFunction
}

function MenuDesktopItem({
  item,
  isActive,
  path,
  isHome,
  isOpen,
  isOffset,
  onOpen,
  onClose,
}: MenuDesktopItemProps) {
  const { title, children } = item
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
    onOpen()
  }

  const handlePopoverClose = () => {
    onClose()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  if (children) {
    return (
      <>
        <LinkStyle
          onClick={handlePopoverOpen}
          sx={{
            ...(isOpen && { opacity: 0.48 }),
          }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
        >
          {title}
          <Box
            component={Icon}
            icon={isOpen ? arrowIosUpwardFill : arrowIosDownwardFill}
            sx={{ ml: 0.5, width: 16, height: 16 }}
          />
        </LinkStyle>
        {isOpen && (
          <Popper
            open={isOpen && open}
            anchorEl={isOpen && anchorEl}
            role={undefined}
            placement="auto"
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper
                  sx={{
                    minWidth: 200,
                    maxWidth: 300,
                    maxHeight: 300,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    mt: 2,
                  }}
                >
                  {/* <Scrollbar
                    sx={{
                      maxHeight: 300,
                      '& .simplebar-content': {
                        maxHeight: 300,
                        display: 'flex',
                        flexDirection: 'column',
                      },
                    }}
                  > */}
                  <ClickAwayListener onClickAway={handlePopoverClose}>
                    <Grid container direction="column">
                      {children?.map(({ subheader, items }, index) => {
                        return (
                          <Grid item key={subheader}>
                            <Typography
                              sx={{
                                maxWidth: 200,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                mx: 2,
                                mt: 1,
                                mb: 0,
                                fontWeight: 600,
                              }}
                            >
                              {subheader}
                            </Typography>
                            <List sx={{ ml: 2, mr: 1 }}>
                              {items?.map(({ title, path }) => (
                                <PreviewNextLink href={path} key={title}>
                                  <ListItemButton>{title}</ListItemButton>
                                </PreviewNextLink>
                              ))}
                            </List>
                            {index !== children?.length - 1 && <Divider />}
                          </Grid>
                        )
                      })}
                    </Grid>
                  </ClickAwayListener>
                  {/* </Scrollbar> */}
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </>
    )
  }

  return (
    <NextLink key={title} href={path}>
      <LinkStyle
        className={title}
        sx={{
          color: (theme) => (theme.palette.mode === 'dark' ? 'text.primary' : 'common.nav'),
          ...(isActive && { color: 'primary.main' }),
        }}
      >
        {title}
      </LinkStyle>
    </NextLink>
  )
}

function CustomNavBar({ page, shopName }) {
  const { id, isEnabled, dropdownlist } = page
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  if (isEnabled && Boolean(dropdownlist?.length)) {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <Button
          sx={{
            p: 0,
            marginRight: '10px',
            minWidth: 'auto',
            ...(open && { opacity: 0.48 }),
            '&:hover': {
              background: 'none',
              opacity: 0.48,
            },
            '&:focus': {
              background: 'none',
            },
            fontWeight: '600',
            textDecoration: 'none',
            color: (theme) => (theme.palette.mode === 'dark' ? 'text.primary' : 'common.nav'),
          }}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handlePopoverOpen}
          disableRipple
        >
          <Typography
            sx={{
              maxWidth: '140px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontWeight: '600',
              textDecoration: 'none',
              fontSize: 'inherit',
            }}
          >
            {id}
          </Typography>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          sx={{
            position: 'center',
          }}
          onClose={handlePopoverClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              minWidth: 200,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {dropdownlist.map((data, idx) => (
            <MenuItem
              key={idx}
              dense
              onClick={() => {
                router.push(`http://${shopName}.${env.NEXT_PUBLIC_HOST_URI}/${data?.pageURL}`)
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                justifyContent={'space-around'}
                sx={{ p: 0.5 }}
              >
                <Typography variant="body1">{data?.page}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    )
  }
  return <></>
}

export default function MenuDesktop({
  isOffset,
  isHome,
  navConfig,
  customNavBar,
  navpageLoader,
}: MenuProps): JSX.Element {
  const { pathname, query } = useRouter()
  const [open, setOpen] = useState(false)
  const shopDetails = getShopName()
  const wallet = useContext(WalletContext)

  useEffect(() => {
    if (open) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleOpen = () => {
    setOpen((prev) => !prev)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {navpageLoader === LoadingState.LOADING ? (
        <Skeleton variant="rectangular" width={210} height={60} />
      ) : (
        <Stack direction="row" alignItems={'center'} sx={{ marginRight: '20px' }}>
          {navConfig.map((link) => {
            const { actualPath, path, title } = link
            let newPath = path
            let isActive = actualPath ? actualPath === pathname : pathname === path
            if (title === 'My Profile') {
              if (!wallet.address) return null
              newPath = path.replace('/profile', `/profile/${wallet.address}`)
              isActive = isActive && wallet.address === query?.address
            }
            return (
              <MenuDesktopItem
                key={link.title}
                item={link}
                path={newPath}
                isActive={isActive}
                isOpen={open}
                onOpen={handleOpen}
                onClose={handleClose}
                isOffset={isOffset}
                isHome={isHome}
              />
            )
          })}
          <Stack direction="row">
            {Boolean(customNavBar?.length && navConfig?.length < 4) &&
              customNavBar?.slice(0, 4 - navConfig?.length)?.map((nav) => {
                return <CustomNavBar key={nav.id} shopName={shopDetails?.shopName} page={nav} />
              })}
          </Stack>
          {Boolean(customNavBar?.slice(4 - navConfig?.length)?.length) && (
            <MenuDesktopItem
              key="More"
              isOpen={open}
              onOpen={handleOpen}
              onClose={handleClose}
              isOffset={isOffset}
              isHome={isHome}
              path=""
              item={{
                path: '',
                title: 'More',
                children: customNavBar
                  ?.slice(4 - navConfig?.length)
                  ?.map(({ id, dropdownlist }) => ({
                    subheader: id,
                    items: dropdownlist?.map(({ page, pageURL }) => ({
                      title: page,
                      path: `http://${shopDetails?.shopName}.${env.NEXT_PUBLIC_HOST_URI}/${pageURL}`,
                    })),
                  })),
              }}
              isActive={false}
            />
          )}
        </Stack>
      )}
    </>
  )
}
